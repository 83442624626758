<template >
	<div class="body">
		<navTop></navTop>
		<div class="wrap">
			<div class="wrap-header">
				<div class="header-left">
					<div class="order-sn">{{ $t('orderCenter.orderSn') }}{{ order.orderSn }}</div>
					<div class="order-status">{{ $t("orderCenter.orderStatus") }}</div>
					<div class="order-status-content">{{ order.status }}</div>
				</div>
				<div class="header-right">
					<div class="tip">
						<p>{{ $t("orderCenter.orderStepTip1") }}</p>
						<p>{{ $t("orderCenter.orderStepTip2") }}</p>
					</div>
					<div class="step">
						<el-steps :active="5" align-center finish-status="process">
							<el-step :title="$t('orderCenter.step1')" icon="el-icon-edit" :description="order.orderSubmitTime"></el-step>
							<el-step :title="$t('orderCenter.step2')" icon="el-icon-upload"></el-step>
							<el-step :title="$t('orderCenter.step3')" icon="el-icon-picture" :description="order.goodsDeliverTime"></el-step>
							<el-step :title="$t('orderCenter.step4')" icon="el-icon-picture" :description="order.goodsReceiveTime"></el-step>
							<el-step :title="$t('orderCenter.step5')" icon="el-icon-picture"></el-step>
						</el-steps>
					</div>
				</div>
			</div>
			<div style="height:20px;background-color: #eee;"></div>
			<div class="wrap-body">
				<div class="consignee">
					<div class="title">{{ $t("orderCenter.consigneeInfo") }}</div>
					<table class="tab-content" border="0" cellpadding="0" cellspacing="0">
						<tr>
							<td>{{ $t("orderCenter.consignee") }}</td>
							<td>{{ $lang(order,"consignee") }}</td>
						</tr>
						<tr>
							<td style="width: 100px;">{{ $t("orderCenter.consigneeAddress") }}</td>
							<td><p>{{ $lang(order,"consigneeAddress") }}</p></td>
						</tr>
						<tr>
							<td>{{ $t("orderCenter.consigneePhone") }}</td>
							<td>{{ order.consigneePhone }}</td>
						</tr>
					</table>
				</div>
				<div class="pay">
					<div class="title">{{ $t("orderCenter.payInfo") }}</div>
					<table class="tab-content" border="0" cellpadding="0" cellspacing="0">
						<tr>
							<td>{{ $t("orderCenter.payMethod") }}</td>
							<td>{{ $lang(order,"payMethod") }}</td>
						</tr>
						<tr>
							<td style="width: 100px;">{{ $t("orderCenter.payTime") }}</td>
							<td>{{ order.payTime }}</td>
						</tr>
					</table>
				</div>
				<div class="deliver">
					<div class="title">{{ $t("orderCenter.deliverInfo") }}</div>
					<table class="tab-content" border="0" cellpadding="0" cellspacing="0">
						<tr>
							<td>{{ $t("orderCenter.deliverMethod") }}</td>
							<td>{{ $lang(order,"deliverMethod") }}</td>
						</tr>
						<tr>
							<td style="width: 80px;">{{ $t("orderCenter.deliverTime") }}</td>
							<td><p>{{ order.deliverTime }}</p></td>
						</tr>
						<tr>
							<td>{{ $t("orderCenter.packNumber") }}</td>
							<td>{{ order.pack }}</td>
						</tr>
					</table>
				</div>
			</div>
			<div style="height:20px;background-color: #eee;"></div>
			<div class="goods">
				<div class="goods-imgs">
					<div class="block">
						<span class="demonstration"></span>
						<el-image>
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline"></i>
							</div>
						</el-image>
				    </div>
				</div>
				<div class="goods-info">
					<table cellpadding="0" cellspacing="0" border="0" class="tab-goods">
						<tr>
							<td style="width: 100px;">{{ $t("orderCenter.goodsName") }}</td>
							<td>{{ $lang(order,"goodsName") }}</td>
						</tr>
						<tr>
							<td>{{ $t("orderCenter.goodsSpec") }}</td>
							<td>{{ order.goodsSpec}}</td>
						</tr>
						<tr>
							<td>{{ $t("orderCenter.goodsType") }}</td>
							<td>{{ $lang(order,"goodsType") }}</td>
						</tr>
						<tr>
							<td>{{ $t("orderCenter.goodsPrice") }}</td>
							<td>{{ order.goodsPrice }}</td>
						</tr>
						<tr>
							<td>{{ $t("orderCenter.goodsNumber") }}</td>
							<td>{{ order.goodsNumber }}</td>
						</tr>
					</table>
				</div>
			</div>
			
			<div class="floor">
				<p>{{ $t("orderCenter.goodsTotal") }}:<span>{{order.goodsTotal}}</span></p>
				<p>{{ $t("orderCenter.packTotal") }}:<span>{{order.packTotal}}</span></p>
				<p>{{ $t("orderCenter.freightTotal") }}:<span>{{order.freightTotal}}</span></p>
				<p class="total">{{ $t("orderCenter.total") }}:<span>{{order.total}}</span></p>
			</div>
		</div>
	</div>
</template>
<script>
import navTop from "@/components/navTop";
export default {
	components: {navTop},
	data() {
		return {
			step:{
				
			},
			order:{
				orderSn:'123456789',
				status:'等待收货',
				orderSubmitTime:"2020-03-19 12:05:06",
				goodsDeliverTime:"2020-03-19 12:05:06",
				goodsReceiveTime:"2020-03-19 12:05:0",
				consigneeZhCn:"张三",
				consigneeEnUs:"zhangsan",
				consigneeAddressZhCn:"反季发分解机飞机飞机覅垃圾覅金额及经费及覅就就覅姐夫姐姐",
				consigneeAddressEnUs:"jfiejfijeifjiejfiej",
				consigneePhone:"19856231234",
				payMethodZhCn:"支付宝",
				payMethodEnUs:"Alipay",
				payTime:"2020-03-06 15:06:16",
				deliverMethodZhCn:"陆运",
				deliverMethodEnUs:"Luyun",
				pack:"100*50*10",
				goodsNameZhCn:"分解纠纷IE积分IE就覅将诶发将诶就覅将诶发觉覅积分将诶发髻",
				goodsNameEnUs:"fjiejijfijjijasifjiej",
				goodsSpec:"ABC-1245",
				goodsTypeZhCn:"免费附件",
				goodsTypeEnUs:"jijifejif fefe",
				goodsPrice:"15",
				goodsNumber:"1",
				goodsTotal:"500.00",
				packTotal:"89.00",
				freightTotal:"12.00",
				total:"620.00",
			},
		}
	},
	methods: {
		
	}
}
</script>
<style  scoped>
.body {
  background-color: #eee;
  width: 100%;
  height:1080px;
  font-size: 14px;
}

.wrap {
  margin: 0 auto;
  width: 1200px;
  background-color: #fff;
  border-top: 4px solid green;
}
.wrap-header{
	display: flex;
	justify-content: space-between;
	height: 240px;
}
.header-left{
	width: 30%;
	text-align: center;
}
.order-sn{
	margin: 20px 0px;
}
.order-status{
	margin:30px 0px;
}
.order-status-content{
	font-size: 28px;
	color:green;
}
.header-right{
	width: 70%;
	text-align: center;
}
.tip{
	text-align: left;
	line-height: 20px;
	margin: 20px;
	color: #888;
	font-size: 14px;
}
.step{
	padding:10px 0px;
}
.wrap-body{
	display:flex;
	flex-direction:row;
	padding:20px 40px;
}
.consignee{
	width:420px;
	border-right: 1px solid #eee;
	padding-right: 20px;
}
.title{
	font-size: 18px;
	margin: 10px 0px;
}
.tab-content tr td{
	padding: 10px 0px;
}
.pay{
	width:360px;
	border-right: 1px solid #eee;
	padding:0px 20px;
}
.deliver{
	padding: 0 20px;
}
.goods{
	display:flex;
	flex-direction:row;
	padding:20px 40px;
}
.goods-imgs{
	width:360px;
}
.goods-info{
	width:500px;
}
.tab-goods tr td{
	padding:10px 0px;
}
.floor{
	border-top:1px solid #eee;
	padding:20px 40px;
}
.floor p{
	text-align: right;
	line-height: 40px;
}
.floor span{
	color: #000000;
	margin-left: 10px;
}
.total span{
	font-size: 24px;
	color: red;
}
</style>
