<template>
    <div style="padding-top: 200px;text-align: center;font-size: 28px">
        <div v-if="res">{{ res }}</div>
        <div v-else>处理中...</div>
    </div>
</template>

<script>
import {parseParameterMap} from "../../utils";
import {payPalExecute} from "../../api/order";

export default {
    name: "PayPalSuccess",
    data() {
        return {
            res: null,
        }
    },
    async created() {
        // paymentId=PAYID-MDI224Y857695430H536960S&token=EC-5BD58320UA442104L&PayerID=XL676UHBDH4M8
        // 拿到 PayPal 支付返回的参数后，请求服务器，完成后续支付流程
        this.res = await payPalExecute(parseParameterMap(location.href));
        console.log("确认支付结果：", this.res);
        //后台返回支付成功后，跳转到订单详情或者关闭这个页面
    }
}
</script>

<style scoped>

</style>